import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes/index';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import authGuard from '@/router/authGuard';
import dashboardGuard from '@/router/dashboardGuard';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authGuard);
router.beforeEach(dashboardGuard);

export default router;
