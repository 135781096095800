const Login = () => import(/* webpackChunkName: "anonymous" */ '@/views/Login');
const Form = () => import(/* webpackChunkName: "anonymous" */ '@/components/Login/Form');
const ForgotPassword = () => import(/* webpackChunkName: "anonymous" */ '@/components/Login/ForgotPassword');
const SetPassword = () => import(/* webpackChunkName: "anonymous" */ '@/components/Login/SetPassword');
const CodeVerification = () => import(/* webpackChunkName: "anonymous" */ '@/components/Login/CodeVerification');

export default [
  {
    path: '/login',
    props: true,
    component: Login,
    meta: {
      anonymous: true,
    },
    children: [
      {
        path: '',
        name: 'login.signIn',
        component: Form,
        meta: {
          billboard: {
            image: 'work_5.jpeg',
            headline: 'login.billboard.headline',
            subheadline: 'login.billboard.subheadline',
          },
        },
      },
      {
        path: 'forgot',
        name: 'login.forgot',
        component: ForgotPassword,
      },
      {
        path: 'setPassword',
        name: 'login.setPassword',
        component: SetPassword,
      },
      {
        path: 'verifyCode',
        name: 'login.verifyCode',
        component: CodeVerification,
        props: true,
      },
    ],
  },
];
