import { apolloClient } from '@/integration/apollo';
import { app } from '@/integration/firebase';
import firebase from 'firebase/app';
import { setUser as setSentryUserContext } from '@/integration/sentry';
import { promisifyWithTimeout } from '@/helpers/promisify';
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import router from '@/plugin/router';

const anonymousUser = Object.freeze({
  email: '',
  name: 'anon.',
  role: 'nobody',
  multiFactor: false,
});

/**
 * @typedef {
 *   {
 *     email: string,
 *     name: string,
 *     role: string
 *   }
 * } User
 */
class Authentication {
  constructor() {
    this.isLogged = undefined;
    this.user = { ...anonymousUser };
    this.userInstance = null;
    this.resolver = null;
    // @TODO
    // this listener need some refactoring
    app.auth().onAuthStateChanged((user) => {
      this.isLogged = Boolean(user);
      if (this.isLogged) {
        const hasMultifactorAuthEnable = Boolean(user.multiFactor.enrolledFactors.length);
        this.userDataChanged({ email: user.email, name: user.displayName, multiFactor: hasMultifactorAuthEnable });
        user.getIdTokenResult()
          .then((idTokenResult) => {
            const role = idTokenResult.claims['https://hasura.io/jwt/claims']['x-hasura-default-role'];
            this.userDataChanged({ role });
          });
        this.userInstance = user;
        apolloClient.resetStore();
      } else {
        if (router.currentRoute.name !== 'login.signIn' && router.currentRoute.name !== null
        && router.currentRoute.name !== 'login.setPassword') {
          router.push({ name: 'login.signIn' });
        }
        this.userDataChanged({ ...anonymousUser });
        apolloClient.stop();
        apolloClient.clearStore();
      }
    });
  }

  /**
   * @todo consider to use RxJS?
   * local isLogged$ = new BehaviorSubject(false);
   * app.auth().onAuthStateChanged(user => isLogged$.next(Boolean(user));
   * return this.isLogged$.asObservable();
   * @todo consider to use RxJS?
   *
   * @returns {Promise<Promise<Boolean> | Promise<unknown>>}
   */
  verifyAuth() {
    return promisifyWithTimeout(() => this.isLogged);
  }

  authenticateAs(email, password) {
    return new Promise((resolve, reject) => {
      app.auth().signInWithEmailAndPassword(email, password)
        .then(({ user }) => {
          if (typeof user === 'undefined') {
            reject(new Error(`There's no account for this email:  ${email}`));
          }
          resolve({ email: user.email, name: user.displayName });
        })
        .catch(reject);
    });
  }

  /**
   * @returns User
   */
  currentUser() {
    return this.user;
  }

  /**
   * @param {Object} newData
   */
  userDataChanged(newData) {
    this.user = { ...this.user, ...newData };
    setSentryUserContext(this.user);
  }

  isGranted(role) {
    return this.currentUser().role === role;
  }

  addResolver(resolver) {
    this.resolver = resolver;
  }

  /**
   * @returns {Promise<void>}
   */
  logout() {
    return app.auth().signOut();
  }

  /**
   * @returns verificationId
   */
  async enableMultifactor(phoneNumber) {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
    const verificationId = await this.userInstance.multiFactor.getSession().then(async (multiFactorSession) => {
      const phoneInfoOptions = {
        phoneNumber,
        session: multiFactorSession,
      };
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationIdentifier = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions, recaptchaVerifier,
      );

      return verificationIdentifier;
    });

    return verificationId;
  }

  async verifyPhoneNumber(verificationId, verificationCode) {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    return this.userInstance.multiFactor.enroll(multiFactorAssertion, 'SMS multi-factor authentication');
  }

  async sendVerificationCode(phoneInfoOptions) {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const verificationIdentifier = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

    return verificationIdentifier;
  }

  async verifyCode(verificationId, verificationCode) {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    await this.resolver.resolveSignIn(multiFactorAssertion);
  }
}

export const authentication = new Authentication();
