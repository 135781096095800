import { n3Roles } from '@/configuration';
import AppSidebar from '@/components/AppSidebar';

export default [
  {
    path: '/management',
    name: 'management',
    redirect: '/management/locations',
    components: {
      default: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management'),
      sidebar: AppSidebar,
    },
    children: [
      {
        name: 'management.locations',
        path: 'locations',
        props: true,
        meta: { authorize: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.adminMidLeader, n3Roles.partner] },
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Locations/List'),
      },
      {
        name: 'management.zones',
        path: 'zones',
        props: true,
        meta: { authorize: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.adminMidLeader, n3Roles.partner] },
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Zones/List'),
      },
      {
        name: 'management.devices',
        path: 'devices',
        props: true,
        meta: { authorize: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.adminMidLeader, n3Roles.partner] },
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Devices/List'),
      },
      {
        name: 'management.clients',
        path: 'clients',
        props: true,
        meta: { authorize: [n3Roles.superadmin, n3Roles.admin, n3Roles.adminMidLeader, n3Roles.partner] },
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Clients/List'),
      },
      {
        name: 'management.users',
        path: 'users',
        meta: { authorize: [n3Roles.superadmin, n3Roles.admin, n3Roles.adminMidLeader, n3Roles.partner] },
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Users/List'),
      },
      {
        name: 'management.notifications',
        path: 'notifications',
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Notifications/List'),
      },
      {
        name: 'management.partners',
        path: 'partners',
        props: true,
        meta: { authorize: [n3Roles.superadmin] },
        component: () => import(/* webpackChunkName: "n3smart" */ '@/views/Management/Partners/List'),
      },
    ],
  },
];
