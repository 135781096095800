<template>
  <v-list-item-group color="primary">
    <v-list-item
      v-for="route in routes"
      :key="JSON.stringify(`${route.name}_${route.label}`)"
      :href="navigationLink(route)"
      :to="navigateTo(route)"
      :target="navigationTarget(route)"
      :ripple="false"
      link
    >
      <v-list-item-icon>
        <v-icon v-text="route.icon" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="route.label" />
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: 'NavigationPages',
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    navigationLink(route) {
      if (route.external) {
      //  return route.external;
        return '';
      }

      return '';
    },
    navigationTarget(route) {
      if (route.external) {
        return '_blank';
      }

      return '_self';
    },
    navigateTo(route) {
      if (route.external) {
        return null;
      }

      const { name, params = {} } = route;

      return { name, params };
    },
  },
};

</script>
