<template>
  <v-menu flat dense :offset-x="true">
    <template #activator="{ on }">
      <v-list nav flat dense>
        <v-list-item :ripple="false" v-on="on">
          <v-list-item-content>
            <v-list-item-title v-if="name">
              {{ name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-avatar color="#c4c4c4" size="24">
              <v-icon small color="white">
                mdi-account
              </v-icon>
            </v-avatar>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-list-item-title>
              <LocaleSwitcher />
            </v-list-item-title>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list v-if="!multiFactor">
        <v-list-item disabled @click="openDialog">
          <v-icon small class="mr-1">
            mdi-cellphone-key
          </v-icon>
          <v-list-item-title>{{ $t("multifactor.enable") }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
  name: 'NavigationProfile',
  components: {
    LocaleSwitcher,
  },
  inject: ['authGuard'],
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    username() {
      if (!this.currentUser.email) {
        return '';
      }

      return this.currentUser.email.trim();
    },
    name() {
      if (!this.currentUser.name) {
        return '';
      }

      return this.currentUser.name.trim();
    },
    multiFactor() {
      return this.currentUser.multiFactor;
    },
  },
  methods: {
    logout() {
      sessionStorage.clear();
      this.authGuard.logout();
    },
    openDialog() {
      this.$emit('openDialog');
    },
  },
};
</script>
