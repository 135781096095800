import en from './english_dictionary.json';
import no from './norwegian_dictionary.json';
import Localization from './Localization';

const languages = {
  en,
  no,
};

const offset = new Date().getTimezoneOffset();

export {
  languages,
  offset,
  Localization,
};
