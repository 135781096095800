export const EnvironmentStatus = Object.freeze({
  GOOD_CONDITIONS: 10,
  PARTIALY_GOOD_CONDITIONS: 5,
  BAD_CONDITIONS: 0,
});

export function fromN3StatusEnvironment(n3status) {
  switch (parseInt(n3status, 10)) {
    case 1:
      return EnvironmentStatus.PARTIALY_GOOD_CONDITIONS;
    case 2:
      return EnvironmentStatus.GOOD_CONDITIONS;
    default:
      return EnvironmentStatus.BAD_CONDITIONS;
  }
}
