import { format, isDate } from 'date-fns';
import { offset } from '@/locale/index';

function formatDate(value, pattern = 'yyyy-MM-dd') {
  if (!value) return '';

  let date = value;
  if (!isDate(date)) {
    date = new Date(date);
  }

  if (!isDate(date)) {
    throw new Error(`Only date variable could be formatted. Got: ${date}`);
  }

  return format(date, pattern);
}

function dateWithTime(value) {
  return formatDate(value, 'yyyy-MM-dd HH:mm:ss');
}

function gmtTime(dtime) {
  const date = new Date(dtime);
  date.setMinutes(date.getMinutes() - offset);
  const isoDate = date.toISOString();

  return isoDate;
}

export {
  formatDate, dateWithTime, gmtTime,
};
