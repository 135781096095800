import NotFound from '@/views/NotFound';
import AppSidebar from '@/components/AppSidebar';
import publicRoutes from './anonymous';
import managementRoutes from './management';
import dashboardRoutes from './dashboard';

export default publicRoutes
  .concat([
    {
      path: '/404',
      name: 'not-found',
      components: {
        default: NotFound,
        sidebar: AppSidebar,
      },
    },
  ])
  .concat(managementRoutes)
  .concat(dashboardRoutes)
  .concat([
    {
      path: '*',
      redirect: { name: 'dashboard', params: { category: 'zones' } },
    },
  ]);
