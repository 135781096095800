<template>
  <v-content class="error404">
    <v-container>
      <v-row align="center" justify="center">
        <v-col align-self="center">
          <v-card>
            <v-card-text class="text-center">
              <h1 class="display-3 mt-5">
                Page not found.
              </h1>
              <v-btn
                color="primary"
                large
                :to="{ name: 'dashboard', params: {category: 'zones'} }"
              >
                back to home
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>

export default {
  name: 'NotFound',
};

</script>
<style lang="scss" scoped>

@import '~@/styles/variables';

.error404 {
  position: relative;
  background: $primary-brand-color;
  overflow: hidden;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url('~@/assets/work_5.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
