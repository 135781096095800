export const promisify = (callback) => new Promise((resolve, reject) => {
  const interval = setInterval(() => {
    try {
      const result = callback();
      if (result !== undefined) {
        resolve(result);
        clearInterval(interval);
      }
    } catch (err) {
      reject(err);
    }
  });
});

const timeoutPromise = (timeout) => new Promise((resolve, reject) => setTimeout(
  () => reject(new Error(`Timeout - Async callback was not invoked within ${timeout} ms`)),
  timeout,
));

export const promisifyWithTimeout = (callback, timeoutValue = 10000) => Promise.race([
  promisify(callback),
  timeoutPromise(timeoutValue),
]);
