import { CleaningStatus } from '@/model/CleaningStatus';
import { EnvironmentStatus } from '@/model/EnvironmentStatus';

const cleaningStatusColor = {};
cleaningStatusColor[CleaningStatus.CLEANED_FULL] = 'rgb(85, 158, 84)'; // green
cleaningStatusColor[CleaningStatus.CLEANED_PARTLY] = 'rgb(253, 196, 53)'; // orange
cleaningStatusColor[CleaningStatus.CLEANED_NONE] = 'rgb(210, 20, 32)'; // red
cleaningStatusColor[CleaningStatus.CLEANED_NOT_REQUIRED] = 'rgb(179, 179, 179)'; // gray

const environmentStatusColor = {};
environmentStatusColor[EnvironmentStatus.GOOD_CONDITIONS] = 'rgb(85, 158, 84)'; // green
environmentStatusColor[EnvironmentStatus.PARTIALY_GOOD_CONDITIONS] = 'rgb(253, 196, 53)'; // orange
environmentStatusColor[EnvironmentStatus.BAD_CONDITIONS] = 'rgb(210, 20, 32)'; // red

const categories = [
  'zones', 'robots', 'environment',
];

const n3Roles = {
  superadmin: 'n3-superadmin',
  admin: 'n3-admin',
  siteinstaller: 'n3-siteinstaller',
  user: 'n3-user',
  adminMidLeader: 'n3-admin-mid-leader',
  partner: 'n3-partner',
};

const theme = {
  colors: {
    primary: '#00465a',
    cleaningStatusColor,
  },
};

const environmentTheme = {
  colors: {
    primary: '#00465a',
    environmentStatusColor,
  },
};

const defaultLocale = 'en';
const availableLanguages = [
  { title: 'English', value: 'en' },
  { title: 'Norsk', value: 'no' },
];

const {
  ENVIRONMENT,
  GRAPHQL: graphqlConfig = {},
  FIREBASE: firebaseConfig = {},
  SENTRY: sentryConfigDefaults = { DSN: null },
  GOOGLE_API_KEY = 'AIzaSyD6XVc38mGGv2bEjuwbh5EdejfF5XiTLNU',
} = window.KevTeebTsa;

const sentryConfig = { ...sentryConfigDefaults, ENVIRONMENT };
const GOOGLE_MAP_SETTINGS = {
  center: { lat: 59.508742, lng: 11.12085 },
  mapTypeId: 'roadmap',
  zoom: 8,
};

const CATEGORY_ZONE = 'CATEGORY_ZONE';
const CATEGORY_ROBOT = 'CATEGORY_ROBOT';
const CATEGORY_ENVIRONMENT = 'CATEGORY_ENVIRONMENT';
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';

const TIME_INTERVAL_30_DAYS = 30 * 24 * 60 * 60 * 1000;
const TIME_INTERVAL_7_DAYS = 7 * 24 * 60 * 60 * 1000;
const TIME_INTERVAL_1_DAY = 24 * 60 * 60 * 1000;
const TIME_INTERVAL_1_HOUR = 60 * 60 * 1000;

export {
  availableLanguages,
  defaultLocale,
  theme,
  graphqlConfig,
  firebaseConfig,
  sentryConfig,
  n3Roles,
  categories,
  GOOGLE_API_KEY,
  GOOGLE_MAP_SETTINGS,
  CATEGORY_ZONE,
  CATEGORY_ROBOT,
  CATEGORY_ENVIRONMENT,
  environmentTheme,
  DATETIME_FORMAT,
  TIME_INTERVAL_30_DAYS,
  TIME_INTERVAL_7_DAYS,
  TIME_INTERVAL_1_DAY,
  TIME_INTERVAL_1_HOUR,
};
