const scrambleEmail = (email) => {
  if (!email) {
    return '';
  }

  const atPosition = email.indexOf('@');

  const visibleFromStart = 2;
  const visibleAtEnd = 3;
  const leftSideAsterix = atPosition - visibleFromStart;
  const rightSideAsterix = email.length - visibleFromStart - visibleAtEnd - atPosition;

  if (leftSideAsterix <= 0) {
    return email;
  }

  let scrambled = email.substr(0, visibleFromStart);
  [...Array(leftSideAsterix).keys()].forEach(() => {
    scrambled += '*';
  });

  scrambled += email.substr(atPosition, visibleFromStart);
  [...Array(rightSideAsterix).keys()].forEach(() => {
    scrambled += '*';
  });

  scrambled += email.substr(email.length - visibleAtEnd, visibleAtEnd);

  return scrambled;
};

const capitalize = (value) => {
  if (!value) return '';

  const stringValue = value.toString();

  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
};

export {
  capitalize,
  scrambleEmail,
};
