<template>
  <div>
    <v-list-item
      v-for="route in routes"
      :key="route.name"
      @click="goTo(route.link)"
    >
      <v-list-item-icon>
        <v-icon v-text="route.icon" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="route.label" />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'NavigationLinks',
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goTo(link) {
      window.open(link, 'Window', 'height=800,width=600').moveBy(500, 100);
    },
  },
};

</script>
