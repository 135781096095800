import { ROUTE_DASHBOARD } from '@/router/routes/dashboard';
import { categories } from '@/configuration';

export default (to, from, next) => {
  const regex = new RegExp(`^${ROUTE_DASHBOARD}(..*|$)`, 'gm');
  const { category } = to.params;
  if (regex.test(to.name) && categories.indexOf(category) < 0) {
    // next({ name: 'not-found' }); // @TODO if we want redirect to /404
    const { name, path } = from;
    if (name === null && path === '/') {
      next({ name: 'dashboard', params: { category: 'zones' } });
    } else {
      next({ name: from.name, params: from.params });
    }
  } else {
    next();
  }
};
