<template>
  <v-snackbar
    v-model="show"
    top
    right
    color="error"
  >
    {{ message }}
    <v-btn text dark @click.native="show = false">
      {{ $t('common.close') }}
    </v-btn>
  </v-snackbar>
</template>

<script>

import { EventBus } from '@/eventBus';

export default {
  name: 'ErrorSnackBar',
  data() {
    return {
      show: false,
      message: '',
    };
  },
  created() {
    EventBus.$on('generalErrorOccurred', (error) => {
      let message = this.$t('common.error.general');
      if (error) {
        // eslint-disable-next-line prefer-destructuring
        message = error.message;
      }
      this.show = true;
      this.message = message;
    });
  },
};

</script>
