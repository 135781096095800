import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { languages } from '@/locale/index';
import { defaultLocale } from '@/configuration';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Object.keys(languages)[0],
  fallbackLocale: defaultLocale,
  messages: Object.assign(languages),
});

export default i18n;
