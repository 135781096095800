import i18n from '@/plugin/i18n';

const required = (value) => (value !== '' && value !== undefined && value !== null) || 'Required.';
const requiredArray = (value) => (value.length !== 0) || 'Required.';
const password = (value) => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;

  return pattern.test(value) || i18n.t('common.setPasswordRule');
};
const email = (value) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.emailRule');
};
const gpsCoordinates = (value) => {
  const pattern = /^([-+]?)[0-9]{1,3}(?:\.[0-9]{1,15})?$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.numberRule');
};

const floatNumberTwoDecimal = (value) => {
  const pattern = /^[0-9]{1,4}(?:\.[0-9]{1,2})?$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.numberRule');
};

const number = (value) => {
  const pattern = /^[0-9]*$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.numberRule');
};

const beaconId = (value) => {
  const pattern = /^[0-9A-Fa-f]{1,4}$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.fourCharsRule');
};

const minMax = (value, max) => {
  if (value < parseInt(max, 10)) {
    return true;
  }

  return i18n.t('common.minMaxRule');
};

const phoneNumber = (value) => {
  const pattern = /^\+?[0-9]{2,3}\s?[0-9]{2,3}\s?[0-9]{2,3}\s?[0-9]{0,3}\s?[0-9]{0,3}$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.phoneRule');
};

const letterOrDigit = (value) => {
  const pattern = /^.*[a-zA-Z0-9].*$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.nameRule');
};

const phoneVerificationCode = (value) => {
  const pattern = /^[0-9]{6}$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || i18n.t('common.sixDigitRule');
};

export {
  required, password, email, gpsCoordinates, floatNumberTwoDecimal, number, beaconId, minMax, phoneNumber, requiredArray,
  letterOrDigit, phoneVerificationCode,
};
