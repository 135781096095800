import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { tokenStorage } from '@/security/TokenStorage';
import { AuthenticationError, TOKEN_UNREACHABLE_CODE } from '@/error/AuthenticationError';
import { graphqlConfig } from '@/configuration';

function addAuthorizationHeader(headers, token) {
  return {
    ...headers,
    authorization: token ? `Bearer ${token}` : null,
  };
}

const cache = new InMemoryCache();
const link = createHttpLink({ uri: graphqlConfig.API });

const withToken = setContext((_, { headers }) => { // eslint-disable-line arrow-body-style
  return tokenStorage.getToken().then((token) => { // eslint-disable-line arrow-body-style
    return {
      headers: addAuthorizationHeader(headers, token),
    };
  });
});

const refreshToken = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      if (err.extensions.code === 'invalid-jwt') {
        return new Observable((observer) => {
          // @todo remove it when token refreshing will be verified
          if (process.env.NODE_ENV === 'development') {
            console.warn('Your session token expires. It will be refreshed. Below you can find old and nnew token'); // eslint-disable-line
            tokenStorage.getToken().then(console.info); // eslint-disable-line
          }

          tokenStorage
            .getToken(true)
            .then((newToken) => {
              // @todo remove it when token refreshing will be verified
              if (process.env.NODE_ENV === 'development') {
                console.info(newToken);// eslint-disable-line
              }
              // Refresh token and modify operation context with a new token
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: addAuthorizationHeader(oldHeaders, newToken),
              });
            })
            .then(() => {
              // Retry last failed request
              forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch((error) => {
              observer.error(new AuthenticationError(error, TOKEN_UNREACHABLE_CODE));
            });
        });
      }
    }
  }

  return forward(operation);
});

const logout = onError(({ networkError }) => {
  if (networkError
    && networkError instanceof AuthenticationError
    && networkError.code === TOKEN_UNREACHABLE_CODE
  ) {
    // @todo Inform user in nice way about that and redirect to logout
    console.warn('You are logged out. Please sign in. Reason: ', networkError.message);  // eslint-disable-line
  }
});

const errorsLink = logout.concat(refreshToken);
const auth = withToken.concat(errorsLink);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: auth.concat(link),
  cache,
});
