import { n3Roles } from '@/configuration';
import AppSidebar from '@/components/AppSidebar';

const Dashboard = () => import(/* webpackChunkName: "n3smart" */ '@/views/Dashboard');
const DashboardBar = () => import(/* webpackChunkName: "n3smart" */ '@/components/DashboardBar');
const DrillDown = () => import(/* webpackChunkName: "n3smart" */ '@/views/DrillDown');
const N3Category = () => import(/* webpackChunkName: "n3smart" */ '@/views/N3Category');

export const ROUTE_DASHBOARD = 'dashboard';
export const ROUTE_DRILLDOWN = `${ROUTE_DASHBOARD}.location`;

export default [
  {
    path: '/:category',
    meta: { authorize: [n3Roles.superadmin, n3Roles.admin, n3Roles.user, n3Roles.adminMidLeader] },
    components: {
      default: N3Category,
      sidebar: AppSidebar,
      appbar: DashboardBar,
    },
    props: {
      default: true,
      appbar: true,
    },
    children: [
      {
        name: ROUTE_DASHBOARD,
        path: '',
        component: Dashboard,
        props: true,
      },
      {
        name: ROUTE_DRILLDOWN,
        path: 'locations/:locationId',
        component: DrillDown,
        props: true,
      },
    ],
  },
];
