<template>
  <v-card min-height="250px">
    <v-card-title>
      {{ $t("multifactor.title") }}
    </v-card-title>
    <v-form
      v-if="!verificationId"
      v-model="validPhoneForm"
      novalidate="true"
      @submit.prevent="handleSubmitPhone"
    >
      <v-card-text>
        <v-alert
          v-if="error"
          type="error"
          outlined
          dense
          class="text-left mt-4"
        >
          {{ error }}
        </v-alert>
        <v-text-field
          key="phone"
          v-model="phone"
          :label="$t('form.label.phone')"
          name="Phone"
          :rules="[rules.phoneNumber, rules.required]"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          type="submit"
          :disabled="!validPhoneForm"
          block
          :loading="loadingPhone"
        >
          {{ $t("multifactor.submit") }}
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-form v-else v-model="validCodeForm" novalidate="true" @submit.prevent="handleSubmitCode">
      <v-card-text>
        <v-alert
          v-if="error"
          type="error"
          outlined
          dense
          class="text-left mt-4"
        >
          {{ error }}
        </v-alert>
        <v-text-field
          key="code"
          v-model="code"
          :label="$t('form.label.code')"
          name="Code"
          :rules="[rules.phoneVerificationCode, rules.required]"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          type="submit"
          :disabled="!validCodeForm"
          block
          :loading="loadingCode"
        >
          {{ $t("multifactor.submit") }}
        </v-btn>
      </v-card-actions>
    </v-form>
    <div v-if="!recaptchaError" id="recaptcha-container" />
  </v-card>
</template>

<script>
import { phoneNumber, required, phoneVerificationCode } from '@/validation/rules';

export default {
  name: 'MultifactorAuth',
  inject: ['authGuard'],
  data() {
    return {
      validPhoneForm: false,
      validCodeForm: false,
      loadingPhone: false,
      loadingCode: false,
      showError: false,
      recaptchaError: false,
      error: '',
      phone: '',
      code: '',
      rules: {
        phoneNumber,
        required,
        phoneVerificationCode,
      },
      verificationId: '',
    };
  },
  methods: {
    async handleSubmitPhone() {
      this.loadingPhone = true;
      await this.authGuard
        .enableMultifactor(this.phone)
        .then((id) => {
          this.verificationId = id;
          this.error = '';
          this.loadingPhone = false;
        })
        .catch((err) => {
          this.showError = true;
          this.recaptchaError = true;
          setTimeout(() => {
            this.recaptchaError = false;
          }, 1000);
          this.error = err.message;
          this.loadingPhone = false;
        });
    },
    handleSubmitCode() {
      this.loadingCode = true;
      this.authGuard.verifyPhoneNumber(this.verificationId, this.code).then(() => {
        this.loadingCode = false;
        this.authGuard.userDataChanged({ multiFactor: true });
        this.$emit('closeDialog');
      })
        .catch((err) => {
          this.showError = true;
          this.error = err.message;
          this.loadingCode = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.currentLanguage {
  &:hover {
    cursor: pointer;
  }
}
</style>
