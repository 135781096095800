<template>
  <v-snackbar
    v-model="show"
    top
    right
    color="success"
  >
    {{ message }}
    <v-btn text dark @click.native="show = false">
      {{ $t('common.close') }}
    </v-btn>
  </v-snackbar>
</template>

<script>

import { EventBus } from '@/eventBus';

export default {
  name: 'SuccessSnackBar',
  data() {
    return {
      show: false,
      message: '',
    };
  },
  created() {
    EventBus.$on('successInfo', (successMessage) => {
      this.show = true;
      this.message = successMessage;
    });
  },
};

</script>
