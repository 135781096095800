<template>
  <v-app id="app">
    <router-view name="sidebar" />
    <router-view name="appbar" />
    <router-view />
    <ErrorSnackBar />
    <SuccessSnackBar />
  </v-app>
</template>

<script>

import ErrorSnackBar from '@/components/ErrorSnackBar';
import SuccessSnackBar from '@/components/SuccessSnackBar';

export default {
  name: 'App',
  components: {
    ErrorSnackBar,
    SuccessSnackBar,
  },
};
</script>
