<template>
  <v-navigation-drawer
    :width="205"
    :mini-variant="$vuetify.breakpoint.smAndDown"
    permanent
    app
  >
    <v-list>
      <router-link
        v-slot="{ href, navigate }"
        :to="{ name: 'dashboard', params: { category: 'zones' } }"
      >
        <a :href="href" @click="navigate">
          <v-list-item class="logo">
            <v-img alt="" src="@/assets/n3smart.png" />
          </v-list-item>
        </a>
      </router-link>
    </v-list>

    <v-divider />

    <v-list nav dense>
      <NavigationPages :routes="dashboardItems" />
      <NavigationLinks :routes="linksItems" />
      <div v-if="navItems.length" class="menu-group-header">
        <span>MANAGEMENT</span>
      </div>
      <NavigationPages :routes="navItems" />
    </v-list>
    <v-dialog v-model="dialog" max-width="500px" disabled>
      <MultifactorAuth @closeDialog="closeDialog" />
    </v-dialog>

    <template #append>
      <NavigationProfile :current-user="currentUser" @openDialog="openDialog" />
    </template>
  </v-navigation-drawer>
</template>

<script>

import NavigationProfile from '@/components/Navigation/NavigationProfile';
import NavigationPages from '@/components/Navigation/NavigationPages';
import NavigationLinks from '@/components/Navigation/NavigationLinks';
import MultifactorAuth from '@/components/MultifactorAuth';
import { EventBus } from '@/eventBus';
import { n3Roles } from '@/configuration';

export default {
  name: 'AppSidebar',
  components: {
    NavigationProfile,
    NavigationPages,
    NavigationLinks,
    MultifactorAuth,
  },
  inject: ['authGuard'],
  data() {
    return {
      dialog: false,
      currentUser: null,
      navigation: [
        {
          label: 'N3Zones', icon: 'mdi-home-analytics', name: 'dashboard', params: { category: 'zones' }, roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.user, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'N3Assets', icon: 'mdi-bank', name: 'dashboard', params: { category: 'robots' }, roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.user, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'N3Environment', icon: 'mdi-weather-hazy', name: 'dashboard', params: { category: 'environment' }, roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.user, n3Roles.adminMidLeader, n3Roles.partner],
        },
      ],
      managementNav: [
        {
          label: 'Locations', icon: 'mdi-map-marker-multiple-outline', name: 'management.locations', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'Zones', icon: 'mdi-domain', name: 'management.zones', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'Devices', icon: 'mdi-robot-vacuum', name: 'management.devices', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'Clients', icon: 'mdi-card-account-details-outline', name: 'management.clients', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'Users', icon: 'mdi-account-outline', name: 'management.users', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'Notifications', icon: 'mdi-bell-outline', name: 'management.notifications', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.siteinstaller, n3Roles.user, n3Roles.adminMidLeader, n3Roles.partner],
        },
        {
          label: 'Partners', icon: 'mdi-handshake-outline', name: 'management.partners', roles: [n3Roles.superadmin],
        },
      ],
      linksNav: [
        {
          label: this.$t('navbar.measures'), icon: 'mdi-earth', name: 'measures', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.user, n3Roles.adminMidLeader, n3Roles.siteinstaller, n3Roles.partner], link: 'https://n3.no/n3smart/naaf-2/tiltak/',
        },
        {
          label: 'FAQ', icon: 'mdi-help-circle-outline', name: 'faq', roles: [n3Roles.superadmin, n3Roles.admin, n3Roles.user, n3Roles.adminMidLeader, n3Roles.siteinstaller, n3Roles.partner], link: 'https://n3.no/n3smart/faq',
        },
      ],
    };
  },
  computed: {
    navItems() {
      return this.managementNav.filter((item) => item.roles.includes(this.currentUser.role));
    },
    dashboardItems() {
      return this.navigation.filter((item) => item.roles.includes(this.currentUser.role));
    },
    linksItems() {
      return this.linksNav.filter((item) => item.roles.includes(this.currentUser.role));
    },
    mainContentClasses() {
      const isManagementPage = this.$route.matched.some((record) => record.name === 'management');

      return { 'app-style': isManagementPage };
    },
  },
  beforeMount() {
    this.currentUser = this.authGuard.currentUser();
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.currentUser = this.authGuard.currentUser();
      EventBus.$emit('successInfo', 'Multifactor Authentication Enabled!');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/variables';

.menu-group-header {
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 16px;
  margin-bottom: 6px;
  > span {
    display: none;
    font-size: 0.75rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: initial;
    }
  }
}
</style>
