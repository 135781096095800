import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { sentryConfig } from '@/configuration';

const { DSN: dsn, ENVIRONMENT: environment } = sentryConfig;

if (dsn && environment) {
  const logErrors = environment === 'development';

  Sentry.init({
    dsn,
    environment,
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors })],
    release: `n3smart-js@${process.env.VUE_APP_VERSION}`,
  });
}

export function setUser(user) {
  const { email: username, role } = user;
  Sentry.configureScope((scope) => {
    scope.setUser({ username, role });
  });
}
