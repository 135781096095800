/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import { authentication } from '@/security/Authentication';

export default (to, from, next) => {
  const isAnonymous = to.matched.some((record) => record.meta.anonymous);
  const { authorize = [] } = to.meta;
  if (isAnonymous) {
    next();
  } else {
    authentication
      .verifyAuth()
      .then((isAuthenticated) => {
        const currentUser = authentication.currentUser();
        if (!isAuthenticated) {
          next({
            path: '/login',
            query: { redirectTo: to.fullPath },
          });
        } else if (authorize.length && !authorize.includes(currentUser.role)) {
          next({ path: '/' });
        } else {
          next();
        }
      });
  }
};
