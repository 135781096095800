import { defaultLocale } from '@/configuration';

export default class Localization {
  constructor(languages, translator) {
    this.languages = languages;
    this.translator = translator;
    this.storage = window.localStorage;
  }

  getLanguage() {
    const storedLanguage = this.unifiesLanguageName(this.storage.getItem('language') || '');
    if (this.isSupported(storedLanguage)) {
      return storedLanguage;
    }

    const browserLang = this.unifiesLanguageName(navigator.language);
    if (this.isSupported(browserLang)) {
      return browserLang;
    }

    return this.unifiesLanguageName(defaultLocale);
  }

  setLanguage(language) {
    let newLanguage = language;
    if (!this.isSupported(newLanguage)) {
      newLanguage = this.unifiesLanguageName(defaultLocale);
    }

    this.translator.locale = newLanguage;
    this.storage.setItem('language', newLanguage);
  }

  isSupported(language) {
    return Object.prototype.hasOwnProperty.call(this.languages, this.unifiesLanguageName(language));
  }

  unifiesLanguageName(language) {
    return (language.split('-')[0]).toLowerCase();
  }
}
