import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { theme } from '@/configuration';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: theme.colors.primary,
        danger: colors.red.base,
      },
    },
  },
});
