import Vue from 'vue';

import 'normalize.css';
import 'chartjs-adapter-date-fns'; // try to not load it globally because it break a tree shaking for date-fns
import '@/filter';
import '@/integration/firebase';
import '@/integration/sentry';
import { capitalize } from '@/helpers/string';
import { authentication } from '@/security/Authentication';
import { languages, Localization } from '@/locale';
import { apolloProvider } from './plugin/apollo';
import i18n from './plugin/i18n';
import router from './plugin/router';
import vuetify from './plugin/vuetify';
import App from './App';

Vue.config.productionTip = false;

const localization = new Localization(languages, i18n);

// eslint-disable-next-line no-extend-native, func-names
String.prototype.capitalize = function () {
  return capitalize(this);
};

new Vue({
  apolloProvider,
  i18n,
  router,
  vuetify,
  provide: {
    localization,
    authGuard: authentication,
  },
  beforeCreate() {
    this.$root.$i18n.locale = localization.getLanguage();
  },
  render: (h) => h(App),
}).$mount('#app');
