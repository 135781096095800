<template>
  <v-menu
    :close-on-click="closeOnClick"
    :close-on-content-click="closeOnContentClick"
    :offset-y="offsetY"
  >
    <template #activator="{ on }">
      <span class="currentLanguage" v-on="on">
        <v-icon small class="mr-1">
          mdi-web
        </v-icon>
        {{ language }}
      </span>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="changeLanguage(item)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import { availableLanguages } from '@/configuration';
import { languages as availableLocales } from '@/locale';

export default {
  name: 'LocaleSwitcher',
  inject: ['localization'],
  data() {
    return {
      closeOnClick: true,
      closeOnContentClick: true,
      offsetY: true,
      items: availableLanguages
        .filter((language) => Object.prototype.hasOwnProperty.call(
          availableLocales, language.value,
        )),
    };
  },
  computed: {
    language() {
      const currentLocale = this.localization.getLanguage();
      const currentLanguage = this.items.find((language) => language.value === currentLocale);

      if (currentLanguage) {
        return `${currentLanguage.title} (${currentLanguage.value.toUpperCase()})`;
      }

      return '';
    },
  },
  methods: {
    changeLanguage(item) {
      this.localization.setLanguage(item.value);
      this.$router.go();
    },
  },
};

</script>

<style lang="scss" scoped>
  .currentLanguage {
    &:hover {
      cursor: pointer;
    }
  }
</style>
