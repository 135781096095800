const CleaningStatus = Object.freeze({
  CLEANED_FULL: 10,
  CLEANED_PARTLY: 5,
  CLEANED_NONE: 0,
  CLEANED_NOT_REQUIRED: 15,
});

export {
  CleaningStatus,
};

export function fromN3Status(n3status) {
  const status = parseInt(n3status, 10);
  if (status === 2) {
    return CleaningStatus.CLEANED_FULL;
  }

  return status === 1 ? CleaningStatus.CLEANED_PARTLY : CleaningStatus.CLEANED_NONE;
}
