import { app } from '@/integration/firebase';

class TokenStorage {
  constructor() {
    this.token = '';
    this.pristine = true;
    app.auth().onIdTokenChanged((user) => {
      if (!user) {
        this.reset();
      } else {
        this.getAuthUserToken(user);
      }
    });
  }

  /**
   * @returns {Promise<String>}
   */
  getToken(forceRefresh = false) {
    if (this.pristine === true || forceRefresh === true) {
      const user = app.auth().currentUser;

      return this.getAuthUserToken(user);
    }

    return new Promise((resolve) => resolve(this.token));
  }

  /**
   * @param {String} token
   */
  setToken(token) {
    this.token = token;
    this.pristine = false;
  }

  reset() {
    this.token = '';
    this.pristine = true;
  }

  getAuthUserToken(user) {
    return new Promise((resolve, reject) => {
      user.getIdToken().then((token) => {
        this.setToken(token);
        resolve(token);
      }).catch(reject);
    });
  }
}

export const tokenStorage = new TokenStorage();
