import firebaseApp from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import { firebaseConfig } from '@/configuration';

const options = {
  apiKey: firebaseConfig.API_KEY,
  authDomain: firebaseConfig.AUTH_DOMAIN,
  databaseURL: firebaseConfig.DB_URL,
  projectId: firebaseConfig.PROJECT_ID,
  storageBucket: firebaseConfig.BUCKET,
  messagingSenderId: firebaseConfig.SENDER_ID,
  appId: firebaseConfig.APP_ID,
  measurementId: firebaseConfig.MEASUREMENT_ID,
};

export const app = firebaseApp.initializeApp(options);
